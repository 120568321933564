import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { Typography } from "@material-tailwind/react";
import CompanyProfileCard from "./CompanyProfileCard";
import { Grid } from "@mui/material";
import ContactForm from "./ContactForm";

export const Contact = ({ data }) => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: "Message sent successfully" });
    } else {
      setStatus({
        succes: false,
        message: "Something went wrong, please try again later.",
      });
    }
  };

  return (
    <section className="contact" id="contact">
      <Typography
        variant="h1"
        style={{ fontWeight: 600, marginTop: 18, marginBottom: 8 }}
      >
        Let’s Connect{" "}
      </Typography>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sm={12}
        lg={8}
        xs={12}
        md={8}
        sx={{
          p: {
            xs: 0,
            sm: 0,
            md: 2,
            lg: 2,
          },
          fontSize: 12,
        }}
      >
        <Grid
          sm={12}
          xs={12}
          md={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center "}
          alignItems={"center"}
          padding={2}
        >
          <Grid
            container
            display={"flex"}
            justifyContent={"center "}
            alignItems={"center"}
          >
            <Grid item lg={12} md={6} sm={6} xs={6}>
              <Typography
                variant="h4"
                style={{ fontWeight: 600, marginBottom: 24, marginTop: 24 }}
              >
                Job Enquiry
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../assets/img/b_phone.png")}
                  alt="ph"
                  style={{ width: 18, height: 18, margin: 12 }}
                />
                <Typography> +91 1143015755</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("mailto:hr@artcubestudio.in");
                }}
              >
                <img
                  src={require("../assets/img/b_web.png")}
                  alt="ph"
                  style={{ width: 18, height: 18, margin: 12 }}
                />
                <Typography>hr@artcubestudio.in</Typography>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/28%C2%B039'14.9%22N+77%C2%B008'11.3%22E/@28.6541329,77.133889,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6541329!4d77.1364639?hl=en&entry=ttu",
                    "_target"
                  );
                }}
              >
                <img
                  src={require("../assets/img/location_icon.png")}
                  alt="ph"
                  style={{ width: 18, height: 18, margin: 12 }}
                />
                <Typography>
                  B-10, First Floor, Tagore Market, Kirti Nagar,
                  New Delhi-110015
                </Typography>
              </div> */}
            </Grid>
            <Grid item lg={12} md={6} sm={6} xs={6}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 600,
                  marginBottom: 24,
                  marginTop: 24,
                }}
              >
                Business Enquiry
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../assets/img/b_phone.png")}
                  alt="ph"
                  style={{ width: 18, height: 18, margin: 12 }}
                />
                <Typography>
                  {" "}
                  +91 {data ? data.mobile : "9871030069"}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("mailto:sureshs@artcubestudio.in");
                }}
              >
                <img
                  src={require("../assets/img/b_web.png")}
                  alt="ph"
                  style={{ width: 18, height: 18, margin: 12 }}
                />
                <Typography>
                  {data ? data.email : "sureshs@artcubestudio.in"}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/28%C2%B039'14.9%22N+77%C2%B008'11.3%22E/@28.6541329,77.133889,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6541329!4d77.1364639?hl=en&entry=ttu",
                    "_target"
                  );
                }}
              >
                <img
                  src={require("../assets/img/b_loc.png")}
                  alt="ph"
                  style={{ width: 18, height: 18, margin: 12 }}
                />
                <Typography>
                  {data
                    ? data.address
                    : "B-10, First Floor, Tagore Market, Kirti Nagar, New Delhi-110015"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={12} xs={12} lg={6} md={8}>
          <ContactForm />
        </Grid>
      </Grid>
    </section>
  );
};
