import { Grid } from "@mui/material";
import React from "react";

export const TeamCard = ({ member }) => {
  const { name, post, image } = member;
  return (
    <Grid item sm={6} xs={12} md={5} lg={3}>
      <div
        style={{
          height: "340px",
          margin: "12px",
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          backgroundColor: "#D9D9D9",
        }}
      >
        <img alt={name} src={image} style={{ height: "250px" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 12,
          }}
        >
          <h4>{name}</h4>
          {post && post !== "" && <p>{post}</p>}
        </div>
      </div>
    </Grid>
  );
};
