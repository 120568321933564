import logo from "./logo.svg";
import "./App.css";
import "react-slideshow-image/dist/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import Counts from "./components/Counts";
import ChooseUs from "./components/ChooseUs";
import { MyFooter } from "./components/MyFooter";
import Home from "./components/page/Home";
// import { BrowserRouter, Route } from "react-router-dom";
// import  Switch  from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./components/page/AboutUs";
import Career from "./components/page/Career";

function App() {
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <NavBar />
      <BrowserRouter>
        {/* <React.Suspense fallback={loading}> */}
        <Routes>
          <Route
            exact
            path=""
            name="Home Page"
            // render={(props) => <Home/>}
            element={<Home />}
          />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/career" element={<Career />} />
        </Routes>
      </BrowserRouter>
      <MyFooter />
    </div>
  );
}

export default App;
