import React from "react";

const MyCard = ({ image, icon, text, desc }) => {
  return (
    <div className="flexBox item">
      {image && image !== null ? (
        <img
          src={image}
          alt="cad"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#212121",
          }}
        ></div>
      )}
      <div style={{ position: "absolute", padding: 8 }} className="our_box">
        <img
          src={icon && icon}
          alt="service"
          style={{
            height: "80px",
            width: "80px",
            margin: 12,
          }}
        />
        <h5>{text && text}</h5>
        <p className="desc_txt">{desc && desc}</p>
      </div>
    </div>
  );
};

export default MyCard;
