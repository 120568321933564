import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import { Slide } from "react-slideshow-image";

const Hero = ({ data }) => {
  const images = [
    require("../assets/img/Banner1.jpg"),
    require("../assets/img/banner-2.jpeg"),
  ];

  const [sliderData, setSliderData] = useState(images);
  const [addMargin, setAddMargin] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1024) {
      setAddMargin(true);
    } else {
      setAddMargin(false);
    }
  };

  const getSliderData = async () => {
    try {
      const response = await axios.get(
        `https://api.artcubestudio.in/getGallery`,
        null
      );
      if (response) {
        console.log("response.data.DATA : ", response.data.DATA);
        setSliderData(response.data.DATA);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };

  useEffect(() => {
    getSliderData();
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["STUDIO"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="overlay"></div>
      <div className="each-slide-effect">
        <Slide autoplay duration={1500} arrows={false} infinite={true}>
          {sliderData?.map((item, index) => {
            return (
              <div
                key={index}
                alt={index}
                style={{
                  width: "100vw",
                  height: "100vh",
                  backgroundImage: item?.image ? `url(${item.image})` : "none",
                  backgroundSize: "cover",
                  backgroundPosition: "top", // centers the image in the div
                  backgroundRepeat: "no-repeat",
                }}
              />
            );
          })}
        </Slide>
      </div>
      <Row
        style={{
          zIndex: 10,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: "100vh",
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TrackVisibility>
          {({ isVisible }) => (
            <div
              className={isVisible ? "animate__animated animate__fadeIn" : ""}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div className="title">{`ARTCUBE`} </div>
              <div className="sub_title" style={{ width: !addMargin && "50%" }}>
                Elevating Design with CAD & BIM <br></br>Transforming Concepts
                into Reality Endless opportunities await
              </div>
            </div>
          )}
        </TrackVisibility>
      </Row>
    </div>
  );
};

export default Hero;
