import React, { useEffect, useState } from "react";
import { Banner } from "../Banner";
import { Skills } from "../Skills";
import { Projects } from "../Projects";
import Counts from "../Counts";
import ChooseUs from "../ChooseUs";
import { Contact } from "../Contact";
import { useLocation } from "react-router-dom";
import Hero from "../Hero";
import axios from "axios";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#contact") {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#skills") {
      const contactSection = document.getElementById("skills");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#projects") {
      const contactSection = document.getElementById("projects");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#contact") {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);


  return (
    <div>
      {/* <Banner /> */}
      <Hero />
      <Skills />
      <Projects />
      <Counts />
      <ChooseUs />
      {/* <Contact /> */}
    </div>
  );
};

export default Home;
