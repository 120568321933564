import { Col } from "react-bootstrap";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CropSquareSharpIcon from "@mui/icons-material/CropSquareSharp";

export const SkillCard = ({ title, description, bgUrl, iconUrl }) => {
  return (
    <div
      className="skill-imgbx"
      style={{
        backgroundImage: `url(${bgUrl})`,
        position: "relative",
        padding: 8,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* <img src={bgUrl} alt={bgUrl}/> */}
      <div
        style={{
          padding: 24,
          height: 350,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={iconUrl}
          alt={iconUrl}
          style={{ width: 128, height: 128, opacity: 20 }}
        />
        <h5 style={{ marginTop: 12 }}>{title}</h5>
      </div>
      <div
        className="proj-txtx"
        style={{
          backgroundImage: `url(${bgUrl})`,
          height: "100%",
          paddingTop: 28,
          paddingLeft: 8,
          paddingRight: 8,
          paddingBottom: 8,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <div className="overlay"></div> */}
        <h4>{title}</h4>
        {description?.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <CropSquareSharpIcon fontSize="14px" />
              <div style={{ textAlign: "left", padding: 8 }}>{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
