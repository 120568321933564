import React from "react";
import MyCard from "./MyCard";

const points = [
  {
    icon: require("../assets/img/wcu_icon1.png"),
    title: "Operational Excellence",
    desc: "We leverage deep insights in architecture, engineering, and construction to empower your enterprise efficiently.",
  },
  {
    icon: require("../assets/img/wcu_icon2.png"),
    title: "Enhancing Business Impact",
    desc: "Outsourcing perceptions shift; we integrate seamlessly to deliver tangible value, defying conventional external partnerships.",
  },
  {
    icon: require("../assets/img/wcu_icon3.png"),
    title: "Expert Industry Knowledge",
    desc: "Leveraging expertise in architecture, engineering, and construction, we equip your enterprise to tackle challenges effectively.",
  },
  {
    icon: require("../assets/img/wcu_icon4.png"),
    title: "Promoting Collaboration",
    desc: "Aligning operational frameworks with client needs fosters collaboration, achieving shared goals in a synchronized environment.",
  },
  {
    icon: require("../assets/img/wcu_icon5.png"),
    title: "Expansive Global Reach",
    desc: "With our Global Delivery Model, clients enhance value, boost productivity, augment revenue, mitigate risks, and reduce expenditure.",
  },
];
const ChooseUs = () => {
  return (
    <div style={{ backgroundColor: "rgb(238, 238, 238)", paddingTop: "60px" }}>
      <div className="skill_title">WHY CHOOSE US</div>
      <div className="flexContainer">
        {points &&
          points.map((item, index) => {
            return (
              <MyCard
                text={item.title}
                desc={item.desc}
                icon={item.icon}
                key={index}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ChooseUs;
