import logo_w from "../assets/img/logo.svg";
import "bootstrap/dist/css/bootstrap.css";

import { Contact } from "./Contact";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

const LINKS = [
  {
    title: "Studio",
    items: [
      {
        name: "About Us",
        onClick: (e) => {
          e.preventDefault();
          window.location.href = "/about-us";
        },
      },
      {
        name: "Team",
        onClick: (e) => {
          e.preventDefault();
          window.location.href = "/about-us";
        },
      },
    ],
  },
  {
    title: "Service",
    items: [
      {
        name: "CAD",
        onClick: null,
      },
      {
        name: "BIM",
        onClick: null,
      },
      {
        name: "MEP",
        onClick: null,
      },
      {
        name: "Visualization",
        onClick: null,
      },
    ],
  },
  {
    title: "Join Us",
    items: [
      {
        name: "Contact us",
        onClick: (e) => {
          e.preventDefault();
          window.location.href = "/#contact";
        },
      },
      {
        name: "Career",
        onClick: (e) => {
          e.preventDefault();
          window.location.href = "/career";
        },
      },
    ],
  },
];

export const MyFooter = () => {
  const [addMargin, setAddMargin] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(
        `https://api.artcubestudio.in/getCompanyProfile`,
        null
      );
      if (response) {
        setData(response.data.DATA);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1024) {
      setAddMargin(true);
    } else {
      setAddMargin(false);
    }
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <Contact data={data} />

      <Grid
        container
        sx={{
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start ",
          backgroundColor: "black",
          color: "white",
        }}
        padding={3}
      >
        <Grid item lg={4} md={4} sm={8} xs={8}>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
            className="flexBox"
          >
            <img
              src={data ? data.logo : logo_w}
              alt="Logo"
              style={{ width: 98, height: 98 }}
            />
            <p
              style={{
                marginTop: 12,
                textAlign: "justify",
                fontWeight: "300",
                fontSize: "14px",
                color: "white",
              }}
            >
              Founded in 2010, ArtCube provides specialized Design Development &
              Documentation for hospitality interior designers. We combine
              skilled professionals and advanced technology to deliver
              high-quality services, earning a strong reputation and becoming a
              valuable partner to our clients.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src={require("../assets/img/phone_icon.png")}
                alt="ph"
                style={{ width: 18, height: 18, margin: 8 }}
              />
              <Typography variant="caption" color={"white"}>
                {" "}
                +91 {data ? data.mobile : "9871030069"}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src={require("../assets/img/web_icon.png")}
                alt="ph"
                style={{ width: 18, height: 18, margin: 8 }}
              />
              <Typography variant="caption" color={"white"}>
                {data ? data.email : "sureshs@artcubestudio.in"}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src={require("../assets/img/location_icon.png")}
                alt="ph"
                style={{ width: 18, height: 18, margin: 8 }}
              />
              <Typography
                variant="caption"
                textAlign={"left"}
                color={"white"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://maps.app.goo.gl/srN6p2sBycKD6WrT8",
                    "_blank"
                  );
                }}
              >
                {data
                  ? data.address
                  : "B-10, First Floor, Tagore Market, Kirti Nagar, New Delhi-110015"}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item lg={8} md={8} sm={4} xs={4} mt={2}>
          <Grid container>
            {LINKS.map((item, index) => {
              return (
                <Grid item lg={4} md={4} sm={8} xs={10} mt={addMargin && 3}>
                  <Typography
                    variant="h5"
                    style={{
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item.items.map((i, n) => {
                    return (
                      <div
                        key={n}
                        className="footer_link"
                        onClick={i.onClick}
                        style={{ color: "white", cursor: "pointer" }}
                      >
                        {i.name}
                      </div>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={addMargin && 2}>
          <div
            style={{
              padding: "8px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              // color: "black",
            }}
          >
            <Typography>Copyright © 2020 ArtCube Design Studio</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src={require("../assets/img/linkedIn.png")}
                alt="linkedIn"
                style={{ width: 18, height: 18, margin: 8 }}
                onClick={() => {
                  window.open(
                    data
                      ? data.linkedin
                      : "https://www.linkedin.com/company/artcube-studio/",
                    "_blank"
                  );
                }}
              />
              <img
                src={require("../assets/img/insta.png")}
                alt="insta"
                style={{ width: 18, height: 18, margin: 8 }}
                onClick={() => {
                  window.open(
                    data
                      ? data.instagram
                      : "https://www.instagram.com/artcubestudio.instahandle/?igsh=cGp6aHIxYmg4ems5",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
