import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";
import { SkillCard } from "./SkillCard";
import { useEffect, useState } from "react";

const skills = [
  {
    desc: [
      "Building Design (SD to CD phase)",
      "As-Built Drawings",
      "Interior/Exterior/Renovation Designs",
      "Site Surveys & Entitlements ",
      "Presentation & Rendering Sets",
    ],
    bg: require("../assets/img/CAD_bg.jpg"),
    icon: require("../assets/img/cad.png"),
    title: "CAD Services",
  },
  {
    desc: [
      "Design & Calculations ",
      "MEPFP Modeling & Documentation",
      "MEP Coordination & Clash Detection",
      "BIM Engineering ",
    ],
    bg: require("../assets/img/MEP_bg.jpg"),
    icon: require("../assets/img/mep.png"),
    title: "MEP Services",
  },
  {
    desc: [
      "2D & 3D Rendering ",
      "SketchUp Models ",
      "Architectural & Interior Design Walkthroughs ",
      "Flyby Animations ",
      "Virtual Tours",
    ],
    bg: require("../assets/img/VS_bg.jpg"),
    icon: require("../assets/img/vs.png"),
    title: "Visualization Services",
  },
  {
    desc: [
      "As-Built Modeling ",
      "LOD 100-500 Models ",
      "SD to CD Documentation ",
      "Revit Family Creation ",
      "Coordination & Clash Detection ",
    ],
    bg: require("../assets/img/BIM_bg.jpg"),
    icon: require("../assets/img/bim.png"),
    title: "BIM Services",
  },
];

export const Skills = ({ data }) => {
  const [showArrows, setShowArrows] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1024) {
      setShowArrows(true);
    } else {
      setShowArrows(false);
    }
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <div className="skill_title">AREAS OF EXPERTIES</div>

              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
                arrows={showArrows}
              >
                {skills &&
                  skills.map((skill, index) => {
                    return (
                      <SkillCard
                        title={skill.title}
                        description={skill.desc}
                        bgUrl={skill.bg}
                        iconUrl={skill.icon}
                        key={index}
                      />
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
