import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TeamCard } from "../TeamCard";
import { Grid } from "@mui/material";
import axios from "axios";

const bg = require("../../assets/img/about.png");

// const team = [
//   {
//     name: "Suresh Shukla",
//     img: require("../../assets/team_pictures/suresh_shukla.jpg"),
//     designation: "DIRECTOR",
//   },
//   {
//     name: "Praveen Kumar",
//     img: require("../../assets/team_pictures/parveen_kumar.jpg"),
//     designation: "STUDIO MANAGER-BIM",
//   },
//   {
//     name: "Shalu Singh",
//     img: require("../../assets/team_pictures/shalu_singh.jpg"),
//     designation: "PROJECT MANAGER-BIM",
//   },
//   {
//     name: "Nancy",
//     img: require("../../assets/team_pictures/nancy.jpg"),
//     designation: "PROJECT MANAGER-BIM",
//   },
//   {
//     name: "Mansoor Ali",
//     img: require("../../assets/team_pictures/mansoor_ali.png"),
//     designation: "PROJECT MANAGER-BIM",
//   },
//   {
//     name: "Vranda Bansal",
//     img: require("../../assets/team_pictures/vranda_bansal.jpg"),
//     designation: "PROJECT MANAGER-BIM",
//   },
//   {
//     name: "Arun Kumar",
//     img: require("../../assets/team_pictures/arun.jpg"),
//     designation: "SENIOR ARCHITECT-BIM",
//   },
//   {
//     name: "Rajesh",
//     img: require("../../assets/team_pictures/Rajesh.jpg"),
//     designation: "SENIOR ARCHITECT-BIM",
//   },
//   {
//     name: "Arshad Kalim",
//     img: require("../../assets/team_pictures/arshad_kalim.jpg"),
//     designation: "ARCHITECT-BIM",
//   },
//   {
//     name: "Kartik Sharma",
//     img: require("../../assets/team_pictures/kartik_sharma.jpg"),
//     designation: "ARCHITECT-BIM",
//   },
//   {
//     name: "Vartika singh",
//     img: require("../../assets/team_pictures/vartika_singh.jpg"),
//     designation: "PROJECT MANAGER",
//   },
//   {
//     name: "Akanksha Arora",
//     img: require("../../assets/team_pictures/akansha_arora.jpg"),
//     designation: "PROJECT MANAGER",
//   },
//   {
//     name: "Avdhesh Chauhan",
//     img: require("../../assets/team_pictures/avdhesh_chauhan.jpg"),
//     designation: "PROJECT MANAGER",
//   },
//   {
//     name: "Kushal Sharma",
//     img: require("../../assets/team_pictures/kushal_sharma.jpg"),
//     designation: "PROJECT MANAGER",
//   },
//   {
//     name: "Yogesh Kumar",
//     img: require("../../assets/team_pictures/yogesh_kumar.jpg"),
//     designation: "SENIOR DESIGNER",
//   },
//   {
//     name: "Anjali Tiwari",
//     img: require("../../assets/team_pictures/anjali_tiwari.jpg"),
//     designation: "SENIOR DESIGNER",
//   },
//   {
//     name: "Rohit Kumar",
//     img: require("../../assets/team_pictures/rohit_kumar.jpg"),
//     designation: "SENIOR DESIGNER",
//   },
//   {
//     name: "Shubham",
//     img: require("../../assets/team_pictures/subham.jpg"),
//     designation: "SENIOR DESIGNER",
//   },
//   {
//     name: "Roshni Singh",
//     img: require("../../assets/team_pictures/roshni_singh.jpg"),
//     designation: "DESIGNER",
//   },
//   {
//     name: "Avinash Tiwari",
//     img: require("../../assets/team_pictures/avinas_tiwari.jpg"),
//     designation: "DESIGNER",
//   },
//   {
//     name: "Mohit Rawat",
//     img: require("../../assets/team_pictures/mohit_rawat.jpg"),
//     designation: "DESIGNER",
//   },
//   {
//     name: "Akansha Kohli",
//     img: require("../../assets/team_pictures/akansha_kohli.jpg"),
//     designation: "DESIGNER",
//   },
//   {
//     name: "Kavita Kaushik",
//     img: require("../../assets/team_pictures/kavita_kaushik.jpg"),
//     designation: "HR MANAGER & OFFICE ADMIN",
//   },
// ];

const AboutUs = () => {
  const [addMargin, setAddMargin] = useState(false);
  const [team, setTeam] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(
        `https://api.artcubestudio.in/getAllTeams`,
        null
      );
      if (response) {
        setTeam(response.data.DATA);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1024) {
      setAddMargin(true);
    } else {
      setAddMargin(false);
    }
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={{ backgroundColor: "#fff", color: "#000" }}>
      <Container
        style={{
          paddingTop: "150px",
          paddingLeft: "18px",
          paddingRight: "18px",
          paddingBottom: "60px",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col
            size={12}
            md={6}
            style={{
              position: "relative",
              zIndex: 2,
            }}
          >
            {addMargin && (
              <div
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 0.4, // Set the opacity here
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                }}
              ></div>
            )}
            <div
              style={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <h1>About ArtCube</h1>
              <p
                style={{
                  textAlign: "justify",
                  marginTop: "24px",
                  marginBottom: "18px",
                }}
              >
                ArtCube was founded in 2010. It is a comprehensive and
                specialised Design Development & Documentation resource for
                Interior designers working in the hospitality sector . We
                combine the talents and experience of professional designers,
                architects and support personnel along with state of art
                technology to deliver truly integrated interior design
                Documentation services.
              </p>
              <p>
                ArtCube’s core value lies in Commitment to Quality and Process
                Optimization. This has led the organization to maintain a good
                reputation and its focus on quality work has made ArtCube a
                valuable partner to it’s clients.
              </p>
              <h1 style={{ marginTop: "48px" }}>Our Team</h1>
            </div>
          </Col>
          {!addMargin && (
            <Col size={12} md={6} style={{ marginBottom: "36px" }}>
              <img src={bg} alt="about" />
            </Col>
          )}
        </Row>
        <Grid
          Container
          className="flexContainer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {team &&
            team.map((member, index) => {
              return <TeamCard member={member} />;
            })}
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
