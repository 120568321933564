import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Grid, Typography } from "@mui/material";
import { Slide } from "react-slideshow-image";
import axios from "axios";

const about =
  "At ArtCube, we believe in fostering an environment where individuals have the tools and opportunities to thrive. We understand that our achievements stem from the dedication of our community, so we make it a priority to acknowledge outstanding performance. Our culture is built on the principles of compassion, collaboration, and recognition, fostering a supportive atmosphere that values diversity and inclusion. This culture not only encourages success but also cultivates innovation across all levels of our organization.";
const points = [
  {
    icon: require("../../assets/img/cadIcon.png"),
    title: "Immense Growth",
    desc: "Leaders cultivate future leaders through training, mentorship, transparent reviews fostering career growth, and academic research opportunities for innovation.",
  },
  {
    icon: require("../../assets/img/cadIcon.png"),
    title: "leave policy",
    desc: "We prioritize supporting employees with extensive leave policies, including 26-week maternity leave and 5-day paternity leave, promoting gender equality.",
  },
  {
    icon: require("../../assets/img/cadIcon.png"),
    title: "Get Together",
    desc: "We build community through social events, leadership retreats, cultural celebrations, and workshops, fostering a supportive work environment for growth.",
  },
];
const Career = () => {
  const [addMargin, setAddMargin] = useState(false);
  const [showSec1, setShowSec1] = useState(false);
  const [showSec2, setShowSec2] = useState(false);
  const [currContent, setCurrContent] = useState(null);
  const [sliderData, setSliderData] = useState([]);
  const [sliderData2, setSliderData2] = useState([]);
  const content1 = {
    img: require("../../assets/team_pictures/girls_group.jpg"),
    heading: "Why is ArtCube a Great Place to Work?",
    desc: "At ArtCube, we are committed to delivering top-tier customer experiences through ethical and professional services that adhere to all legal and environmental standards. Our diverse workforce, encompassing various ages, genders, and ethnicities, embodies our belief in equal opportunities. We foster an inclusive environment where a talented, multicultural team is valued and celebrated, ensuring everyone can achieve their full potential.",
  };

  const content2 = {
    img: require("../../assets/team_pictures/boys_group.jpg"),
    heading: "People Development",
    desc: "In a constantly evolving business, people must grow with it. ArtCube's comprehensive training and coaching program allows employees to learn and progress, ensuring they are well-equipped for any role. There are numerous opportunities to build an exciting and fulfilling career, advance to senior positions, and move across different verticals throughout India.",
  };
  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 900) {
      setAddMargin(true);
    } else {
      setAddMargin(false);
    }
  };

  const getSliderData1 = async () => {
    try {
      const response = await axios.get(
        `https://api.artcubestudio.in/getCulturalGallery`,
        null
      );
      if (response) {
        console.log("response.data.DATA : ", response.data.DATA);

        setSliderData(response.data.DATA);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };
  const getSliderData2 = async () => {
    try {
      const response = await axios.get(
        `https://api.artcubestudio.in/getPeopleDevGallery`,
        null
      );
      if (response) {
        console.log("response.data.DATA : ", response.data.DATA);

        setSliderData2(response.data.DATA);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };

  useEffect(() => {
    getSliderData1();
    getSliderData2();
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log("here");
    if (showSec1) {
      console.log("here1");
      setCurrContent(content1);
    }
    // else {
    //   console.log("here2");
    //   setCurrContent(null);
    // }
  }, [showSec1]);

  useEffect(() => {
    console.log("here 1");
    if (showSec2) {
      console.log("here 2");
      setCurrContent(content2);
    }
  }, [showSec2]);

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${require("../../assets/img/career.png")})`,
          color: "#fff",
          height: "600px",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Row
            style={{
              paddingTop: "150px",
              paddingLeft: "18px",
              paddingRight: "18px",
              paddingBottom: "60px",
            }}
          >
            <Col xs={12} md={4} xl={3}>
              <h1 style={{ textAlign: "left" }}>
                LET'S CREATE EXPRIENCES THAT MATTER.
              </h1>
              <p>
                Bring your talents to Art Cube and help us create the future of
                architectural experiences.
              </p>
              <span
                className="btn"
                style={{ marginTop: "16px" }}
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/artcube-studio/jobs/"
                  );
                }}
              >
                Find Career
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          color: "#000",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "60%", textAlign: "center", marginTop: "38px" }}>
          <div className="skill_title">Why ARTCUBE?</div>
          <p color="#494949" style={{ marginTop: "18px" }}>
            {about}
          </p>
        </div>
        <div style={{ textAlign: "center", marginTop: "38px", width: "100%" }}>
          <div className="skill_title">Perks of being in ArtCube</div>

          <Grid container sx={{ color: "#fff" }} p={8}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ background: "#D9D9D9", color: "#000" }}
                  // p={2}
                >
                  <div
                    style={{
                      width: "100%",
                      height: addMargin ? "200px" : "100%",
                      textAlign: "left",
                      padding: 18,
                      position: "relative",
                    }}
                    onClick={() => {
                      if (showSec2) {
                        setShowSec2(false);
                        setCurrContent(null);
                      }
                      if (showSec1) {
                        setShowSec1(false);
                        setCurrContent(null);
                      } else {
                        setShowSec1(true);
                      }
                    }}
                  >
                    <Typography variant="h4">Culture</Typography>
                    <Typography variant="body2" mt={2}>
                      ArtCube's work culture embodies its dedication to equality
                      and excellence.
                    </Typography>

                    {!showSec1 ? (
                      <img
                        src={require("../../assets/img/b_right.png")}
                        alt="b_right"
                        style={{
                          width: 54,
                          margin: 8,
                          position: "absolute",
                          bottom: 12,
                          right: 12,
                        }}
                      />
                    ) : (
                      <img
                        src={require("../../assets/img/b_left.png")}
                        alt="b_left"
                        style={{
                          width: 54,
                          margin: 8,
                          position: "absolute",
                          bottom: 12,
                          right: 12,
                        }}
                      />
                    )}
                  </div>
                </Grid>
                {!addMargin && (
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ background: "#E1E1E1" }}
                    p={2}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      <Slide
                        autoplay
                        duration={1500}
                        arrows={false}
                        infinite={true}
                      >
                        {sliderData?.map((item, index) => {
                          return (
                            <div className="each-slide-effect" key={index}>
                              <div
                                // src={item?.image}
                                alt={index}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  backgroundImage: item?.image
                                    ? `url(${item.image})`
                                    : "none",
                                  backgroundSize: "contain",
                                  backgroundPosition: "top", // centers the image in the div
                                  backgroundRepeat: "no-repeat",
                                }}
                              />
                            </div>
                          );
                        })}
                      </Slide>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {addMargin && showSec1 && currContent && (
              <Grid container sx={{ color: "#fff" }}>
                <Grid
                  item
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  // sx={{ background: "red" }}
                  marginTop={2}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "250px",
                      textAlign: "left",
                      padding: 18,
                      position: "relative",
                    }}
                  >
                    <Slide
                      autoplay
                      duration={1500}
                      arrows={false}
                      infinite={true}
                    >
                      {sliderData2?.map((item, index) => {
                        return (
                          <div className="each-slide-effect" key={index}>
                            <div
                              // src={item?.image}
                              alt={index}
                              style={{
                                width: "100%",
                                height: "200px",
                                backgroundImage: item?.image
                                  ? `url(${item.image})`
                                  : "none",
                                backgroundSize: "contain",
                                backgroundPosition: "top", // centers the image in the div
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                          </div>
                        );
                      })}
                    </Slide>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md={7}
                  sm={12}
                  xs={12}
                  sx={{ background: "#D9D9D9", color: "black" }}
                  p={2}
                >
                  <div
                    style={{
                      minHeight: "250px",
                      textAlign: "left",
                      padding: 18,
                      position: "relative",
                    }}
                  >
                    <Typography variant="h4">{currContent?.heading}</Typography>
                    <Typography variant="body2" mt={2} textAlign={"justify"}>
                      {currContent.desc}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div
                style={{
                  height: addMargin ? "200px" : "100%",
                  textAlign: "left",
                  padding: 18,
                  position: "relative",
                  background: "#1E1E1E",
                  marginLeft: !addMargin && 12,
                  marginTop: addMargin && 12,
                }}
                onClick={() => {
                  if (showSec1) {
                    setShowSec1(false);
                    setCurrContent(null);
                  }
                  if (showSec2) {
                    setShowSec2(false);
                    setCurrContent(null);
                  } else {
                    setShowSec2(true);
                  }
                }}
              >
                <Typography variant="h4">People development</Typography>
                <Typography variant="body2" mt={2}>
                  We cultivate an environment that supports staff in achieving
                  their full potential.
                </Typography>
                {!showSec2 ? (
                  <img
                    src={require("../../assets/img/w_right.png")}
                    alt="w_right"
                    style={{
                      width: 54,
                      margin: 8,
                      position: "absolute",
                      bottom: 12,
                      right: 12,
                    }}
                  />
                ) : (
                  <img
                    src={require("../../assets/img/w_left.png")}
                    alt="w_left"
                    style={{
                      width: 54,
                      margin: 8,
                      position: "absolute",
                      bottom: 12,
                      right: 12,
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          {(!addMargin || showSec2) && currContent && (
            <Grid container sx={{ color: "#fff" }} px={8}>
              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                marginTop={addMargin && -4}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    textAlign: "left",
                    padding: 18,
                    position: "relative",
                  }}
                >
                  <Slide
                    autoplay
                    duration={1500}
                    arrows={false}
                    infinite={true}
                  >
                    {showSec1
                      ? sliderData?.map((item, index) => {
                          return (
                            <div className="each-slide-effect" key={index}>
                              <div
                                // src={item?.image}
                                alt={index}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  backgroundImage: item?.image
                                    ? `url(${item.image})`
                                    : "none",
                                  backgroundSize: "contain",
                                  backgroundPosition: "top", // centers the image in the div
                                  backgroundRepeat: "no-repeat",
                                }}
                              />
                            </div>
                          );
                        })
                      : sliderData2?.map((item, index) => {
                          return (
                            <div className="each-slide-effect" key={index}>
                              <div
                                // src={item?.image}
                                alt={index}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  backgroundImage: item?.image
                                    ? `url(${item.image})`
                                    : "none",
                                  backgroundSize: "contain",
                                  backgroundPosition: "top", // centers the image in the div
                                  backgroundRepeat: "no-repeat",
                                }}
                              />
                            </div>
                          );
                        })}
                  </Slide>
                </div>
              </Grid>
              <Grid
                item
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{ background: "#D9D9D9", color: "black" }}
                p={2}
              >
                <div
                  style={{
                    minHeight: "200px",
                    textAlign: "left",
                    padding: 18,
                    position: "relative",
                  }}
                >
                  <Typography variant="h4">{currContent?.heading}</Typography>
                  <Typography variant="body2" mt={2} textAlign={"justify"}>
                    {currContent.desc}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        <div
          style={{
            background: "#000000",
            width: "100%",
            padding: 18,
            color: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 18,
          }}
        >
          <h1>Interested in joining us?</h1>
          <p style={{ fontSize: "12px", fontWeight: 100 }}>
            Send us your CV on hr@artcubestudio.in
          </p>
        </div>
      </div>
    </div>
  );
};

export default Career;
