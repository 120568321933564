import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";

function ContactForm() {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [type, setType] = useState();
  const [messages, setMessages] = useState();

  const send = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://api.artcubestudio.in/sendContactDetails`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          type: type,
          messages: messages,
        }
      );
      if (response) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setType("");
        setMessages("");
        alert("Query saved Successfully");
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        borderRadius: 2,
        // bgcolor: "background.paper",
        boxShadow: 3,
        alignSelf: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Please note, all fields are required
      </Typography>

      <Box
        component="form"
        noValidate={false}
        autoComplete="off"
        onSubmit={send}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            required
            value={firstName && firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{ marginRight: 1, background: "#ffffff80" }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ marginLeft: 1, background: "#ffffff80" }}
          />
        </Box>

        <TextField
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          sx={{ background: "#ffffff80" }}
        />

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Looking for</FormLabel>
          <RadioGroup
            row
            aria-required
            aria-label="lookingFor"
            name="lookingFor"
            value={type} // Bind the value to the state
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <FormControlLabel
              value="Looking for Enquiries"
              control={<Radio />}
              label="for Enquiries"
            />
            <FormControlLabel
              value="Looking for Business"
              control={<Radio />}
              label="for Business"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          label="Let's start a conversation"
          variant="outlined"
          fullWidth
          required
          value={messages}
          onChange={(e) => setMessages(e.target.value)}
          multiline
          rows={4}
          margin="normal"
          sx={{ background: "#ffffff80" }}
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default ContactForm;
