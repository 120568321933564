import React from "react";

const dataCount = [
  {
    title: "Projects worldWide",
    count: "1200+",
    icon: require("../assets/img/project_icon.png"),
  },
  {
    title: "Glorious Years",
    count: "14",
    icon: require("../assets/img/glorious_icon.png"),
  },
  {
    title: "Client Retention Rate",
    count: "99%",
    icon: require("../assets/img/client_icon.png"),
  },
  {
    title: "Clients Globally",
    count: "100+",
    icon: require("../assets/img/client_count.png"),
  },
];
const Counts = () => {
  return (
    <div className="flexContainer data_count">
      {dataCount &&
        dataCount.map((item, index) => {
          return (
            <div key={index} className="flexBox data_countbx">
              <img
                src={item.icon}
                alt={item.icon}
                style={{ width: "60px", height: "60px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "75%",
                  height: "100%",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <p className="count_tx">{item.count}</p>
                <p className="count_titx">{item.title}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Counts;
